<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/business_units">Business Units</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a :href="`/business_units/${code}`">{{code}}</a>
    </div>
    <div class="row issue-wrapper" v-if="bunit._id">
      <div class="col col-auto-5 issue-col p-4 bg-white">
        <div class="row px-2">
          <div class="col-5 pt-2">
            <h6 class="text-header">Business unit name</h6>
            <input class="form-control" placeholder="Business unit name" v-model="bunit.name"/>
            <br>
            <h6 class="text-dark text-header">Business unit code</h6>
            <input class="form-control" placeholder="Business unit code" v-model="bunit.code" disabled/>
            <br>
            <div class="w-100 text-right">
              <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="updateBUnit">
                <i class="btn p-0 text-white fas fa-save"></i>&nbsp; Update Business Unit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      code: String
    },
    data() {
      return {
        bunit: {
          code: '',
          name: ''
        },
        bunits: []
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      getBUnits: async function(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/bunits`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          this.bunits = response.data.bunits
          this.loader(false)
        } catch (error) {
          console.log(error)
          this.loader(false)
        }
        this.getBUnit();
      },
      getBUnit: function(){
        this.bunit = this.bunits.find(r => `${r.code}` === `${this.code}`)
      },
      updateBUnit: async function(){
        this.loader(true);
        this.errors = []
        if(this.bunit.name.trim() === '')
          this.errors.push('Name cannot be empty')
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Business Unit Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else
          try {
            const response = await this.$http.put(
              `${this.$apiEndpoint}/v1/bunits/${this.bunit._id}`,
              { bunit: { name: this.bunit.name } },
              {
                headers: {
                  'Authorization': this.$userData.authToken,
                  'Access-Control-Allow-Origin' : '*',
                }
              }
            )
            if(response.data.bunit && response.data.bunit._id) {
              this.getBUnits()
              this.$alertify({
                group: 'notification',
                title: 'Update successful',
                type: 'success',
                text: 'Your changes have been saved.'
              })
            }
            this.loader(false)
          } catch (error) {
            console.log(error)
            this.loader(false)
          }
      },
      validateUser: async function(){
       if(this.$userData.user.role !== 'Admin'){
         this.$alertify({
           group: 'notification',
           title: `Access denied`,
           type: 'warning',
           text: `You don't have access to this module.`
         })
         await this.$sleep(500);
         window.history.back()
       }
      },
    },
    mounted: function() {
      this.getBUnits()
      this.validateUser()
    }
  }
</script>
